h1 {
    font-weight: 400 !important;
    font-size: 2.125rem !important;
    line-height: 1.235 !important;
}

h2 {
    font-weight: 400 !important;
    font-size: 1.5rem !important;
    line-height: 1.334 !important;
}

h3 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
}