.responsive-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;

  @media (min-width: 800px) {
    max-width: 600px;
  }
}

#bill-modal-content-wrapper {
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  padding: 12px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  overflow: auto;

  @media (max-width: 1000px) {
    width: 90%;
  }
}

.bill-modal-content {
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: center; 
  margin: 30px; 
  flex-wrap: wrap;
}