.navbar-logo {
    @media (min-width: 290px) {
      height: 4rem;
    }
    height: 3rem;
  }
  
  .app-bar {
    align-items: center;
  }
  
  .test-env {
    background-color: #fa8072;
    color: #000;
  }
  