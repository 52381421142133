.home-card-style {
    color: #f9fafa;
    background-color: #263746;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    min-height: 300px;
    @media (max-width: 1200px) {
      min-height: none;
      border-radius: 0;;
    }
  }
  .home-card-style2 {
    color: #263746;
    background-color: #f5f5f5;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    min-height: 300px;
    @media (max-width: 1200px) {
      min-height: none;
      border-radius: 0;
    }
  }
h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400 !important;
  font-size: 3rem !important;
  line-height: 1.167 !important;
}