.conservice-Toolbar {
    justify-content: center;
    background-color: #212121;
  }
  
  .icon-Color {
    color: #ffffff
  }

  .footer-Typography {
    font-size: .75rem;
  }