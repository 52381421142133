body {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.modal-content-wrapper{
    max-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 12px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24;
    overflow: auto;
    @media (max-width: 1000px) {
      width: 90%;
    }
}

.form-modal-content-wrapper{
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 12px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  overflow: auto;
  @media (max-width: 750px) {
    width: 90%;
  }
}

.card-width {
  width: 600px;
  margin: 0;
  @media (max-width: 540px) {
    width: 100%;
  }
}

.content-wrapper {
  padding-bottom: 9rem;
}

@media (min-height: 1020) {
  .content-wrapper {
    padding-bottom: 2.5rem;
  }
}

.textbox-width {
  width: 100%;
  @media (min-width: 540px) {
    width: 325px;
  }
}

.align-content-center {
  align-content: center;
}

.information-notification-bubble {
  padding: 0.5rem;
  background-color: rgba(73, 174, 207, 0.3);
}
